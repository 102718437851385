






import { Vue, Component } from 'vue-property-decorator'
import Page from '@/components/layouts/page.vue'

@Component({
  components: {
    Page,
  },
})
export default class ImportFile extends Vue {
  hideNavbar = false

  mounted() {
    const route = this.$route
    if (route.query.token) {
      this.hideNavbar = true
    }
  }
}
